.VideoCard {
  display: flex;
  color: black;
  text-decoration: none;
  margin: 2rem 0;
  background: white;
  border: 1px solid var(--lightGrey);
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  height: 180px;
  width: 100%;
}

@media (max-device-width: 768px){
  .VideoCard { 
    height: 25rem;
  }
}

@media (max-device-width: 480px){
  .VideoCard { 
    flex-direction: column;
    align-items: center;
    height: 43rem;
  }
}

@media (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (orientation: landscape) { 
    .VideoCard { 
      height: 12em;
    }
}

@media (min-device-width: 375px) 
  and (max-device-width: 768px) 
  and (orientation: landscape) { 
    .VideoCard { 
      height: 27rem;
    }
}

.VideoCard:hover,
.VideoCard:focus {
  transform: translateY(-1px);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
}

.VideoDetails {
  margin: 8px 8px 0px 8px;
}
