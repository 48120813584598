.Product {
  text-decoration: none;
  margin: 2rem;
  background: white;
  color: black;
  border: 1px solid var(--lightGrey);
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  height: 43.5rem;
  width: 90%;
  overflow: visible;
  display: flex;
}

.Product:hover,
.Product:focus {
  transform: translateY(-1px);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
}
.ImageContainer {
  position: relative;
}
.Image--ReverseButton {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 3rem;
  opacity: 0.8;
  text-align: center;
}
.Product--Image {
  
  height: 43.3rem;
  width: 30rem;
  object-fit: cover;
}
.Product--Image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Product--Title {
  margin: 0;
  padding-left: 2.5rem;
  font-size: large;
}

.Product--Content {
  padding: 0 2rem 1rem;
  height: 19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Product--Category {
  font-size: 1rem;
  text-align: right;
}
.Product--Vars {
  display: grid;
  gap: 8px;
  overflow: visible;
}
.preference {
  display: flex;
  justify-content: space-between;
}
.Product--Buy {
  align-self: center;
}

@media (max-width: 768px) {
  .Product {
    object-fit: cover;
    
    
  }
}

@media (max-width: 428px) {
  .Product {
    object-fit: cover;
    width: 30rem;
    height: 38.5rem;
    margin: 0rem 2rem 2rem 2rem;
    flex-direction: column;
  }

  .Product--Image {
  height: 20rem;
  }
   img {
    margin: 0;
  }
  .Image--ReverseButton {
    
    bottom: 6px;
    
  }

  .Product--Content {
    height: 16rem;
  }
}
