.Store--Listing {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
}

.section {
    padding: 2rem 0;
}



@media (max-width: 810px) {
    .Store--Listing {
      grid-auto-flow: row;
    grid-template-columns: 1fr;

    }
  }

@media (max-width: 414px) {
    .section {
      padding: 1rem 0;
    }
}