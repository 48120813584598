.PostCard {
  text-decoration: none;
  margin: 2rem;
  background: white;
  color: black;
  border: 1px solid var(--lightGrey);
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  height: 41.5rem;
}

.PostCard:hover,
.PostCard:focus {
  transform: translateY(-1px);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
}

.PostCard--Image {
  margin: 0 0 2rem 0;
  width: 100%;
  height: 19rem;
}
.PostCard--Image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.PostCard--Title {
  margin: 0;
}

.PostCard--Content {
  padding: 0 2.5rem 2.5rem;
  height: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.PostCard--Category {
  font-size: 1rem;
  text-align: right;
}
