.Hero {
  background-color: var(--darkGrey);
  color: white;
  padding: 2.5rem 0;
}

.Hero-large {
  padding: 20rem 0;
}

.Hero--Title {
  font-size: 6rem;
  margin-bottom: 0;
}

.Hero--Subtitle {
  font-weight: 400;
  font-size: 1.8rem;
}

.Hero .container {
  height:247px;
}

@media (max-width: 480px){
  .Hero .container { 
    height: 175px;
  }
}

.Hero .BackgroundImage{
  background-position: 50% 42%;
}