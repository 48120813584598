.NavLink {
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 500;
  transition: color 0.2s, border-bottom-color 0.2s;
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
}

.NavLink + .NavLink {
  margin-left: 1rem;
}

.NavLink:hover,
.NavLink.active,
.NavLink:focus {
  color: var(--primary);
  border-bottom-color: currentColor;
}

@media (max-width: 480px){
  .NavLink { 
    padding: 0.5rem 0.5rem;
   

  }
}