.Nav {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.Nav--Container {
  display: flex;
  align-items: center;
  height: 6rem;
}

.Nav .Logo {
  margin-right: 3rem;
}

@media (max-width: 414px){
 .Nav .Logo { 
    margin-right: 1rem;
  }
}
