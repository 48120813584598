.SinglePost {
}

.SinglePost--BackgroundImage {
  height: 78rem;
  top: 60px;
  background-position: top center;
}


.SinglePost--BackButton {
  color: var(--primary);
  background-color: rgba(245,245,245,0.7);
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.1em;
  width: 71px;
}

.SinglePost--BackButton.noimage {
  color: black;
}

.SinglePost--BackButton svg {
  transition: transform 0.2s ease-out;
  transform: translateX(0);
  height: 1.2em;
}

.SinglePost--BackButton:hover svg,
.SinglePost--BackButton:focus svg {
  transform: translateX(-0.25em);
}

.SinglePost--Content {
  background: white;
  margin-top: 30rem;
  padding: 8rem 8rem 4rem 8rem;
}

.SinglePost--Content.noimage {
  margin-top: 1rem;
}

div.resize img{
  max-width: 300px;
}

@media screen and (max-width: 750px) {
  .SinglePost--Content {
    padding: 4rem 2rem;
  }
}

.SinglePost--Meta {
  font-size: 1rem;
  margin: 0 0 2rem 0;
  text-align: center;
}

.SinglePost--Meta > * + * {
  margin-left: 0.75em;
}

.SinglePost--Title {
  text-align: center;
  line-height: 1.2;
}

.SinglePost--InnerContent {
  padding: 0 8rem;
}


.SinglePost--Pagination {
  margin: 4rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SinglePost--Pagination--Link {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
}

.SinglePost--Pagination--Link.next {
  margin-left: auto;
}


@media screen and (max-width: 768px) {
  .SinglePost--BackgroundImage {
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  .SinglePost--InnerContent {
    padding: 0;
  }

  .SinglePost--Content {
    padding: 4rem 2rem;
    margin-top: 18rem;
  }
}

@media screen and (max-width: 414px) {
  .SinglePost--Content {
    margin-top: 3rem;
  }

  div.SinglePost--InnerContent iframe {
    max-width: 300px;
    max-height: 169px;
  }

  div.resize img{
    max-width: 160px;
  }
}