.PageHeader {
  background-color: var(--darkGrey);
  color: white;
  padding: 10rem 0;
}

@media (max-width: 480px){
  .PageHeader { 
    padding: 5rem 0;
  }
}

.PageHeader-large {
  padding: 20rem 0;
}

.PageHeader--Title {
  font-size: 6rem;
  margin-bottom: 0;
  font-family: 'Bebas Neue', cursive;
}

.PageHeader--Subtitle {
  font-weight: 400;
  font-size: 1.8rem;
}

.PageHeader .BackgroundImage{
  background-position: 50% 42%;
}